<template>
  <section id="prospect">
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Customer Information">
          <b-row>
            <b-col md="5" class="border-right">
              <b-row>
                <b-col md="8" lg="6">
                  <div class="detail-info">
                    <span>Customer Name</span>
                    <h4>{{customerInformation.tp_client_name || '-'}} - {{ customerInformation.tp_client_type == 'residencial' ? 'Residential' : 'Company' }}</h4>
                  </div>
                  <div class="detail-info" v-if="customerInformation.tp_client_type == 'company'">
                    <span>Company Name</span>
                    <h4>{{customerInformation.tp_client_company || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Contact</span>
                    <h4 v-if="customerInformation.tp_client_email"><a :href="'mailto:' + customerInformation.tp_client_email" class="contact-info"><i class="ti-email"></i><span>{{ customerInformation.tp_client_email || '-'}}</span></a></h4>
                    <h4 v-if="customerInformation.tp_client_phone"><a :href="'https://web.whatsapp.com/send?phone='+customerInformation.tp_client_phone.replace('08', '628')" target="child" class="contact-info"><i class="fab fa-whatsapp"></i><span>{{ customerInformation.tp_client_phone || '-'}}</span></a></h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Address</span>
                    <h4>{{ customerInformation.tp_client_address || '-' }}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Source</span>
                    <h4>{{ $parent.prospectSrc(customerInformation.tp_source).label || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Contact Via</span>
                    <h4>{{ $parent.contactMedia(customerInformation.tp_contact_via).label || '-'}}</h4>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="5">
              <b-row>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Prospect Date</span>
                    <h4>{{ customerInformation.tp_date | moment('LLLL')}}</h4>
                  </div>
                </b-col>
                <b-col md=8>
                  <div class="detail-info">
                    <span>Category</span>
                    <h4>{{ customerInformation.mpct_name || '-' }}</h4>
                  </div>
                </b-col>
                <b-col md=8>
                  <div class="detail-info">
                    <span>Purpose</span>
                    <h4>{{ customerInformation.tp_keperluan || '-' }}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>General Information</span>
                    <h4>{{customerInformation.tp_general_information || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Input By</span>
                    <h4>{{customerInformation.bu_full_name || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8" v-if="customerInformation.tp_status_prospect == 'P'">
                  <div class="detail-info">
                    <span>Pending Reason</span>
                    <h4>{{customerInformation.tp_pending_reason || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8" v-else-if="customerInformation.tp_status_prospect == 'R'">
                  <div class="detail-info">
                    <span>Reject Reason</span>
                    <h4>{{customerInformation.tp_reject_reason || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Status</span>
                    <div>
                      <b-badge :variant="$parent.prospectBadge[customerInformation.tp_status_prospect].color">
                        {{ $parent.prospectBadge[customerInformation.tp_status_prospect].word }}
                      </b-badge>
                    </div>
                    <b-button
                      variant="success"
                      class="mt-1"
                      v-b-tooltip.hover.noninteractive="'Add Follow Up Result'"
                      v-if="$parent.moduleRole('add_follow_up') && customerInformation.tp_status_prospect == 'FU'"
                      @click="openModalFollowUp(customerInformation)"
                    >
                      <i class="fas fa-file-alt mr-2"></i> Add Follow Up Result
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Follow Up History">
          <b-button
            variant="success"
            class="mb-4"
            v-b-tooltip.hover.noninteractive="'Add Follow Up Result'"
            v-if="$parent.moduleRole('add_follow_up') && customerInformation.tp_status_prospect == 'FU'"
            @click="openModalFollowUp(customerInformation)"
          >
            <i class="fas fa-file-alt mr-2"></i> Add Follow Up Result
          </b-button>
          <template v-if="$parent.lengthArr(followUpHistory)">
            <b-row>
              <b-col lg="8" md="10">
                <div class="timeline">
                  <div class="timeline-box" v-for="(value, key) in followUpHistory" :key="key">
                    <div class="timeline-head">
                      <h4 class="timeline__title">{{ value.tfu_date | moment('LL') }}</h4>
                      <b-badge v-if="value.tfu_status_done == 'done'" :variant="$parent.prospectBadge[value.tfu_status].color">
                        {{ $parent.prospectBadge[value.tfu_status].word }}
                      </b-badge>
                      <p v-else>Not Finished yet</p>
                      <span v-if="value.tfu_status_done == 'done'">Followed Up by: {{ value.tfu_person }}</span>
                    </div>
                    <div class="timeline-body">
                      <p>{{ value.tfu_result }}</p>
                      <p v-if="value.tfu_pending_reason">Pending Reason: {{ value.tfu_pending_reason }}</p>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <h3>Follow Up History is Empty.</h3>
          </template>
        </b-tab>
        <b-tab title="Survey History" v-if="$parent.lengthArr(surveyHistory)">
          <template v-if="$parent.lengthArr(surveyHistory)">
            <b-row>
              <b-col lg="6" md="8">
                <div class="detail-info">
                  <span>Survey Address</span>
                  <h4>{{ (surveyHistory[0] || {tss_address: ''}).tss_address }}</h4>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8" md="10">
                <div class="timeline">
                  <div class="timeline-box" v-for="(value, index) in surveyHistory" :key="index">

                    <div class="timeline-head">
                      <h4 class="timeline__title d-block">{{ value.tss_date | moment('LLL') }}</h4>                      
                      <template v-for="(v, k) in value.tss_image">
                        <b-button v-if="v" size="sm" variant="outline-info" @click="setLightBox(v)" :key="k" class="mt-2 mb-2" :class="k != 0 ? 'ml-2' : ''">                          
                          <i class="fas fa-image"></i>
                          See Photo
                        </b-button>

                        <b-button
                          class="ml-2 btn-icon"
                          variant="outline-warning"
                          v-b-tooltip.hover="'Edit Photo'"
                          v-if="v"
                          @click="showUpdateImage(v, value.tss_id, k)"
                          size="sm"
                          :key="'edit'+k"
                        ><i class="fas fa-pencil-alt"></i>
                        </b-button>
                      </template>
                      <p>Surveyor: {{ value.ms_name }}</p>
                    </div>
                    <div class="timeline-body">
                      <p>{{value.tss_result}}</p>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <h3>Survey History is Empty.</h3>
          </template>
        </b-tab>
        <!-- <b-tab title="Payment History"></b-tab> -->
      </b-tabs>
    </b-card>
    <div class="media-overlay" :class="showSurveyImg ? 'show' : ''" @click.stop="showSurveyImg = false">
      <div class="media-content" @click.stop>
        <div class="media-dialog parent-wrapper">
          <b-img :src="$parent.uploader(detailImg)" />
          <b-button size="sm" @click.stop="showSurveyImg = false" class="close-button btn" variant="danger"><i class="fas fa-times"></i></b-button>
        </div>
      </div>
    </div>

  <b-modal id="updateImage" title="Update Image" centered no-close-on-backdrop>
    <validation-observer v-slot="{ handleSubmit }" ref="VFormUpdateImage">
      <b-form @submit.prevent="handleSubmit(submitUpdateImage())">
        <b-form-row>
          <b-col md=12>
            <b-form-group label-for="monPhoto">
              <template #label>Photo</template>
              <Uploader v-model="dataModal.tss_image" :usePreview="true" type="survey_result"/>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <b-button size="md" variant="transparent" @click="closeModal('updateImage')">Cancel</b-button>
      <b-button size="md" variant="info" @click="submitUpdateImage()">Update</b-button>
    </template>
  </b-modal>
  <b-modal id="followUpResultDetail"
    title="Follow Up Result"
    centered
    no-close-on-backdrop
  >
    <validation-observer v-slot="{ handleSubmit }" ref="VFormFollowUpDetail">
      <b-form @submit.prevent="handleSubmit(addFollowUp())">
        <b-form-group label="Follow Up Date">
          <date-picker value-type="format" format="YYYY-MM-DD" id="followDate" prefix-class="crm" v-model="dataModal.tfu_date" :disabled-date="$parent.notBeforeToday" />
          <VValidate name="Follow Up Date" v-model="dataModal.tfu_date" rules="required" />
        </b-form-group>
        <b-form-group label="Follow Up By">
          <v-select id="folPIC" v-model="dataModal.tfu_person" :options="mrUser" :reduce="v => v.label"></v-select>
          <VValidate name="Follow Up By" v-model="dataModal.tfu_person" rules="required" />
        </b-form-group>
        <b-form-group label="Follow Up Result">
          <b-form-textarea id="folResult" v-model="dataModal.tfu_result" placeholder="e.g. customer setuju untuk melakukan pemasangan meja marmer" rows="3" />
          <VValidate name="Result" v-model="dataModal.tfu_result" rules="required|min:5" />
        </b-form-group>
        <b-form-group label="Status" label-for="proStatus">
          <b-form-radio-group :options="$parent.statusProspectOptions" v-model="dataModal.tfu_status" />
          <VValidate name="Status" v-model="dataModal.tfu_status" rules="required" />
        </b-form-group>
        <b-form-row>
          <template v-if="dataModal.tfu_status == 'FU'">
            <b-col md="12">
              <b-form-group label="Next Follow Up Schedule">
                <date-picker value-type="format" format="YYYY-MM-DD" id="followDate" prefix-class="crm" v-model="dataModal.next" :disabled-date="$parent.notBeforeTodayFollowUp" />
                <VValidate name="Date" v-model="dataModal.next" rules="required" />
              </b-form-group>
            </b-col>
          </template>
           <template v-else-if="dataModal.tfu_status == 'S'">
            <b-col md="6">
              <b-form-group label="Surveyor" label-for="proSurveyor">
                <v-select id="proSurveyor" v-model="dataModal.tss_pic" :options="mrSurveyor" label="label" :reduce="v => v.value" placeholder="Select Surveyor" />
              </b-form-group>
              <VValidate name="Surveyor" rules="required" v-model="dataModal.tss_pic" />
            </b-col>
            <b-col md="6">
              <b-form-group label="Survey Schedule" label-for="proSurveySchedule">
                <date-picker type="datetime" value-type="format" format="YYYY-MM-DD HH:mm:ss" id="proSurveySchedule"  prefix-class="crm" v-model="dataModal.tss_date" :disabled-date="$parent.notBeforeToday"/>
                <VValidate name="Survey Schedule" rules="required" v-model="dataModal.tss_date" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Survey Address" label-for="proSurveyAddress">
                <b-form-textarea id="proSurveyAddress" rows="3" v-model="dataModal.tss_address" />
                <VValidate name="Survey Address" rules="required|min:5" v-model="dataModal.tss_address" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Contact Person Name" label-for="proSurvCP">
                <b-form-input id="proSurvCP" v-model="dataModal.tss_contact_person_name" />
                <VValidate name="Contact Person Name" rules="required|min:3" v-model="dataModal.tss_contact_person_name" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Contact Person Phone" label-for="proSurvCPPhone">
                <b-form-input id="proSurvCPPhone" v-model="dataModal.tss_contact_person_phone" type="number" :formatter="$parent.telp" placeholder="e.g Mobile Number"></b-form-input>
                <VValidate name="Mobile Number" v-model="dataModal.tss_contact_person_phone" :rules="{regex:/^(\+\d{1,3}[- ]?)?\d{10,13}$/, required: true}" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Note" label-for="proSurveyNote">
                <b-form-textarea id="proSurveyNote" rows="3" v-model="dataModal.tss_note" />
                <VValidate name="Note" rules="" v-model="dataModal.tss_note" />
              </b-form-group>
            </b-col>
          </template>
          <template v-else-if="dataModal.tfu_status == 'C'">            
            <b-col md="12">
              <b-form-group label="Closing Date" label-for="proClosingDate">
                <date-picker type="date" value-type="format" format="YYYY-MM-DD" id="proClosingDate"  prefix-class="crm" v-model="dataModal.tp_closing_date" :disabled-date="$parent.notAfterToday" />
                <VValidate name="Closing Date" rules="required" v-model="dataModal.tp_closing_date" />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Project Worth" label-for="proPrice">
                <b-input-group prepend="Rp.">
                  <money id="proPrice" v-model="dataModal.tp_total_price" v-bind="$parent.maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                </b-input-group>
                <VValidate name="Project Worth" rules="required|min_value:100000" v-model="dataModal.tp_total_price" />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <div class="border p-3 mb-3">
                <h5 class="font-weight-bold">Revenue</h5>
                <b-form-row class="mb-2">
                  <b-col md="6">
                    <b-form-group label="Partner Revenue" label-for="proPartRevenue" class="mb-0">
                      <b-input-group prepend="Rp.">
                        <money id="proPartRevenue" @input="setRevenue(dataModal)" v-model="dataModal.tp_revenue_partner" v-bind="$parent.maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                      </b-input-group>                      
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Company Revenue" label-for="proCompRevenue" class="mb-0">
                      <b-input-group prepend="Rp.">
                        <money id="proCompRevenue" disabled v-model="dataModal.tp_revenue_bu" v-bind="$parent.maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                      </b-input-group>                      
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col md="6">
                      <b-form-group label="Percent Partner" label-for="percentPartner">
                          <b-input-group append="%">
                          <b-form-input disabled type="number" min="0" id="percentPartner" v-model="percent_partner" v-bind="$parent.maskCurrency" placeholder="cth: 70" class="form-control"></b-form-input>
                          </b-input-group>
                          <VValidate name="Percent Partner" rules="required|max_value:100|min_value:1" v-model="percent_partner" />
                      </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Percent Company" label-for="percentBu">
                      <b-input-group append="%">
                        <b-form-input disabled type="number" min="0" id="percentBu" v-model="percent_bu" v-bind="$parent.maskCurrency" placeholder="cth: 30" class="form-control"></b-form-input>
                      </b-input-group>
                      <VValidate name="Percent Company" rules="required|max_value:100|min_value:1" v-model="percent_bu" />
                    </b-form-group>
                  </b-col>      
                </b-form-row>                
              </div>
            </b-col>

            <b-col md="12">
              <hr>
            </b-col>

            <b-col md="12">
              <b-form-group label="Using DP ?">
                <b-form-radio-group id="usingDP" :options="$parent.optionsDP" v-model="byUsingDP" />              
              </b-form-group>
            </b-col>
            <!-- Section DP -->
            <template v-if="byUsingDP">
              <b-col md="6">
                <b-form-group label="Down Payment (DP)" label-for="proDP">
                  <b-input-group prepend="Rp.">
                    <money id="proDP" v-model="dataModal.tp_dp" @input="setPriceCompany(dataModal)" v-bind="$parent.maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                  </b-input-group>
                  <VValidate name="Down Payment (DP)" :rules="{ required: true, min_value: 0, max_value: dataModal.tp_total_price }" v-model="dataModal.tp_dp" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Date Down Payment (DP)" label-for="tpDate">
                  <date-picker v-model="dataModal.tp_dp_date" value-type="format" format="YYYY-MM-DD" id="tpDate" prefix-class="crm"  />
                </b-form-group>
                <VValidate name="Date Down Payment (DP)" rules="required" v-model="dataModal.tp_dp_date" />
              </b-col>
              <b-col md="12">
                <div class="border p-3 mb-3">
                  <h5 class="font-weight-bold">Down Payment Shares</h5>
                  <b-form-row>
                    <b-col md="6">
                      <b-form-group label="Partner Share" label-for="proPartShare" class="mb-0">
                        <b-input-group prepend="Rp.">
                          <money id="proPartShare" @input="setPriceCompany(dataModal)" v-model="dataModal.tp_price_partner" v-bind="$parent.maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                        </b-input-group>
                        <VValidate name="Company Share" :rules="{ required: true, min_value: 0, max_value: dataModal.tp_dp }" v-model="dataModal.tp_price_partner" />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Company Share" label-for="proCompShare" class="mb-0">
                        <b-input-group prepend="Rp.">
                          <money id="proCompShare" disabled v-model="dataModal.tp_price_bu" v-bind="$parent.maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                        </b-input-group>
                        <VValidate name="Company Share" :rules="{ required: true, min_value: 0, max_value: dataModal.tp_dp }" v-model="dataModal.tp_price_bu" />
                      </b-form-group>
                    </b-col>
                  </b-form-row>                
                </div>
              </b-col>
              <b-col md="12">
                <b-form-group label="Description" label-for="proDP">
                  <b-form-input id="description" v-model="dataModal.tcp_description" placeholder="e.g DP 50%" />
                  <VValidate name="Description" :rules="{ required: true, min: 2, max: 128 }" v-model="dataModal.tcp_description" />
                </b-form-group>
              </b-col>
            </template>
            <!--  -->

            <template v-else>
              <b-col md="12">
                <b-form-group label="Transfer to Partner?" label-for="bailout">
                  <b-input-group prepend="Rp.">
                    <money id="bailout" v-model="dataModal.tp_bailout" v-bind="$parent.maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                  </b-input-group>
                  <VValidate name="Transfer to Partner?" :rules="{max_value: dataModal.tp_total_price}" v-model="dataModal.tp_bailout" />
                </b-form-group>
              </b-col>
            </template>
            <b-col md="12">
              <b-form-group label="Partner Assignment" label-for="proPartner">
                <v-select id="proPartner" :options="mrPartner" :reduce="v => v.mp_id" v-model="dataModal.tp_partner" label="mp_name" placeholder="Choose a partner to be assigned" />
                <VValidate name="Partner Assignment" rules="required" v-model="dataModal.tp_partner" />
              </b-form-group>
            </b-col>
            <div class="w-100"></div>
            <b-col md="12">
              <b-form-group label="Project Date" label-for="proProjectDate">
                <date-picker @input="changeRangeDate(dataModal)" range v-model="dataModal.tp_range_date" value-type="format" format="YYYY-MM-DD" id="proProjectDate" prefix-class="crm" :disabled-date="$parent.notBeforeToday" />
              </b-form-group>
              <VValidate name="Project Date" rules="required" v-model="dataModal.tp_range_date" />
            </b-col>
            <b-col md="6">
              <b-form-group label="Status Project" label-for="customerStatus">
                <b-form-radio-group id="customerStatus" :options="$parent.closingStatus" v-model="dataModal.tp_status_customer" />
                <VValidate name="Status Project" rules="required" v-model="dataModal.tp_status_customer" />
              </b-form-group>
            </b-col>
          </template>
          <template v-if="dataModal.tfu_status == 'R' || dataModal.tfu_status == 'P'">
            <b-col md="12">
              <b-form-group label-for="proReason" v-if="dataModal.tfu_status == 'R'">
                <template #label>
                  Rejection Reason <small>(Optional)</small>
                </template>
                <b-form-textarea v-model="dataModal.tp_reject_reason" rows="3" placeholder="e.g. Customer masih belum yakin untuk membuat furnitur custom" />
              </b-form-group>
              <b-form-group label-for="proReason" v-else-if="dataModal.tfu_status == 'P'">
                <template #label>
                  Pending Reason <small>(Optional)</small>
                </template>
                <b-form-textarea v-model="dataModal.tp_pending_reason" rows="3" placeholder="e.g. Customer masih belum yakin untuk membuat furnitur custom" />
              </b-form-group>
            </b-col>
          </template>
        </b-form-row>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <b-button size="md" variant="transparent" @click="closeModal('followUpResultDetail')">Cancel</b-button>
      <b-button size="md" variant="info" @click="addFollowUp()">Submit Result</b-button>
    </template>
  </b-modal>

  </section>
</template>
<script>
import Gen from '@/libs/Gen'

  export default{
    props:{
      customerInformation: Object,
      followUpHistory: Array,
      surveyHistory: Array,
      detailImg: String,
      mrUser: Array,
      mrSurveyor: Array,
      mrPartner: Array
    },
    data() {
      return {
        showSurveyImg: false,
        dataModal: {},
        percent_partner: 0,
        percent_bu: 0,
        byUsingDP: false
      }
    },
    methods: {
      setRevenue(data){
        const hasilCompany = data['tp_total_price'] - data['tp_revenue_partner'] 
        data['tp_revenue_bu'] = hasilCompany
        this.$set(this, 'percent_bu', this.$parent.getPercentage(hasilCompany, data['tp_total_price']))
        this.$set(this, 'percent_partner', this.$parent.getPercentage(data['tp_revenue_partner'], data['tp_total_price']))
      },
      openModalFollowUp(data){
        this.dataModal = Object.assign({}, data)
        if(this.mrUser.find(v => v.value == this.$parent.fullName)){
          this.$set(this.dataModal, 'tfu_person', this.$parent.fullName)
        }
        this.$set(this.dataModal, 'tfu_status', data.tp_status_prospect)
        this.$bvModal.show('followUpResultDetail')
      },
      addFollowUp(){
      this.$refs.VFormFollowUpDetail.validate().then(success => {
        if(!success) return
        this.$swal({
          title: 'Submit Follow Up Result...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        Gen.apiRest(
          '/do/' + this.$parent.modulePage,
          { data: {type: 'add-followUp', ...this.dataModal, tp_percent_partner: this.percent_partner, tp_percent_bu: this.percent_bu, using_dp: this.byUsingDP} },
          'POST'
        ).then(res => {
          if(res){
              this.$swal({
                title: res.data.message,
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.$parent.apiGet()
                this.closeModal('followUpResultDetail')
              })
          }
        }).catch(() => {
            this.$parent.apiGet()
            this.$bvModal.hide('followUpResultDetail')
        })
      })
    },
      setLightBox(src){
        this.detailImg = src
        this.showSurveyImg = true
      },
      showUpdateImage(tss_image, tss_id, k){
        this.dataModal = {tss_image, tss_id, key: k}
        this.$bvModal.show('updateImage')
      },
      submitUpdateImage(){
        this.$refs.VFormUpdateImage.validate().then(success => {
          if(!success) return

          this.$swal({
            title: 'Update image...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })

          Gen.apiRest(
            '/do/' + this.$parent.modulePage,
            { data: {type: 'update-image-survey', ...this.dataModal} },
            'POST'
          ).then(res => {
            if(res){
              this.$swal({
                title: res.data.message,
                text: '',
                timer: 1500,
                icon: 'success'
              }).then(() => {
                this.$parent.apiGet()
                this.$bvModal.hide('updateImage')
              }).catch(() => {
                this.$parent.apiGet()
                this.$bvModal.hide('updateImage')
              })
            }
          })

        })
      },
      closeModal(id){
        this.$set(this, 'dataModal', {})
        this.$bvModal.hide(id)
      }
    },
    mounted(){
      setTimeout(() => {       
        if(!this.customerInformation) this.$parent.$router.push({ name: this.$parent.modulePage })      
       }, 800)
    }
  }
</script>
