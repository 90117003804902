<template>
<b-container fluid>
  <bo-page-title />

  <b-card-header v-if="isList">
    <div class="d-flex justify-content-between">
      <b-button-group>
        <b-button size="sm" @click="setActiveMenu('')" :variant="active_filter == '' ? 'info' : 'outline-info'">All ({{total_all}})</b-button>
        <b-button size="sm" @click="setActiveMenu('FU')" :variant="active_filter == 'FU' ? 'info' : 'outline-info'">Follow Up ({{total_fu}})</b-button>
        <b-button size="sm" @click="setActiveMenu('S')" :variant="active_filter == 'S' ? 'info' : 'outline-info'">Survey ({{total_survey}})</b-button>
        <b-button size="sm" @click="setActiveMenu('R')" :variant="active_filter == 'R' ? 'info' : 'outline-info'">Reject ({{total_reject}})</b-button>
        <b-button size="sm" @click="setActiveMenu('P')" :variant="active_filter == 'P' ? 'info' : 'outline-info'">Pending ({{total_pending}})</b-button>
      </b-button-group>
      <div>
        
        <b-dropdown
          variant="success"
          :to="{ name: $route.name, params: { slug: 'add' } }"
          v-if="moduleRole('add')"
        >
          <template #button-content>
            <i class="fa fa-plus-circle mr-2" />
            Create Prospect
          </template>
          <b-dropdown-item @click="createProspect(false)">New Prospect</b-dropdown-item>
          <b-dropdown-item @click="createProspect(true)">Form Existing Data</b-dropdown-item>
        </b-dropdown>
      </div>

    </div>
  </b-card-header>

  <b-card v-if="isList" no-body>
    <b-card-header>
      <b-row>
        <b-col md="3">
          <date-picker value-type="format" format="YYYY-MM-DD" range prefix-class="crm" v-model="dateFilter" @input="doFilterDate" v-b-tooltip.hover.noninteractive="'Filter by Date'" :disabled-date="notAfterToday" />
        </b-col>
        <b-col lg="3">
          <b-form-group>
            <v-select placeholder="Select an Inputter" label="label" :reduce="v => v.value.toString()" @input="doFilter()" v-model="filter.inputter" :options="inputter" :clearable="true"></v-select>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group>
            <v-select placeholder="Select Customer Type" label="label" :reduce="v => v.value" @input="doFilter()" v-model="filter.ct" :options="customerTypeFilter"></v-select>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group>
            <v-select placeholder="Select Category" label="label" :reduce="v => v.value" @input="doFilter()" v-model="filter.category" :options="prospectCategory" :clearable="true"></v-select>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group>
            <v-select placeholder="Select Prospect Source" label="label" :reduce="v => v.value" @input="doFilter()" v-model="filter.ps" :options="prospectSourceFilter"></v-select>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group>
            <v-select placeholder="Select Contact Media" label="label" :reduce="v => v.value" @input="doFilter()" v-model="filter.cm" :options="contactMediaFilter"></v-select>
          </b-form-group>
        </b-col>
        <b-col lg="3" v-if="active_filter == 'FU'">
          <b-form-group>
            <v-select placeholder="Sort By" label="label" :reduce="v => v.value" @input="doFilter()" v-model="filter.srt" :options="sortingBy"></v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="justify-content-end">
        <b-col md=4>
          <SearchInput :value.sync="filter.search" @search="doFilter" />
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body class="p-0">
      <b-table
        :fields="fields"
        :items="dataList||[]"
        :primary-key="idKey"
        :busy="!dataList"
        responsive
        show-empty
        :tbody-tr-class="hasFollowup"
        bordered
        striped
      >
        <template #empty>
          <div class="text-center">
            <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
            <h4 align="center"><span v-if="Object.keys($route.query).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-3 text-info"></b-spinner>
            <strong class="text-info">Loading...</strong>
          </div>
        </template>
        <template #cell(number)="v">
          {{(data.per_page*(data.current_page-1))+v.index+1}}
        </template>
        <template #cell(tp_client_name)="data">
          <div><small>{{ data.item.tp_code }}</small></div>
          <div>{{ data.value }} - {{ data.item.tp_client_type == 'residencial' ? 'Residential' : 'Company' }}</div>
          <a v-if="data.item.tp_client_email" :href="'mailto: '+data.item.tp_client_email" class="contact-info"><i class="ti-email"></i><span>{{ data.item.tp_client_email }}</span></a>
          <a v-if="data.item.tp_client_phone" :href="'https://web.whatsapp.com/send?phone='+data.item.tp_client_phone.replace('08', '628')" target="child" class="contact-info"><i class="fab fa-whatsapp"></i><span>{{ data.item.tp_client_phone }}</span></a>
        </template>
        <template #cell(tp_status_prospect)="data">          
          <b-badge :variant="prospectBadge[data.value].color">
            {{ prospectBadge[data.value].word }}
          </b-badge>
          <div v-if="data.value == 'FU'">
            <small>Next Follow Up:</small> 
            <small>{{ data.item.tfu_date | moment('LL') }}</small>
          </div>
          <div v-if="data.value == 'S'">
            <small v-if="data.item.tss_id">Survey on: {{ data.item.tss_date | moment('LLL') }}</small>
          </div>
        </template>
        <template #cell(tp_date)="data">
          {{ data.value | moment("LLLL") }}
        </template>
        <template #cell(tp_contact_via)="data">
          {{ contactMedia(data.value).label }}
        </template>
        <template #cell(tp_source)="data">
          {{ prospectSrc(data.value).label }}
        </template>
        <template #cell(tp_keperluan)="data">
          <p v-if="data.item.mpct_name">Kategori: {{data.item.mpct_name}}</p>
          <span>{{data.value}}</span>
        </template>
        <template #cell(action)="data">
          <div class="d-flex align-items-center" v-if="data.item.tp_status_prospect == 'FU'">
            <b-button
              class="btn-icon btn-icon-lg"
              variant="success"
              v-if="moduleRole('add_follow_up')"
              v-b-tooltip.hover.noninteractive="'Add Follow Result'"
              @click="openModalFollowUp(data.item)"
            >
              <i class="fas fa-file-alt"></i>
            </b-button>
            <div class="d-flex flex-column">
              <b-button
                class="btn-icon"
                variant="outline-info"
                v-b-tooltip.hover.noninteractive="'View Detail'"
                :to="{ name: $route.name, params: { pageSlug: 'detail', pageId: data.item.tp_code } }"
              ><i class="fas fa-eye"></i>
              </b-button>
              <b-button
                class="btn-icon"
                variant="outline-warning"
                v-b-tooltip.hover.noninteractive="'Edit Prospect'"
                v-if="moduleRole('edit')"
                @click="editProspect(data.item)"
              ><i class="fas fa-pencil-alt"></i>
              </b-button>
            </div>
          </div>
          <template v-else-if="data.item.tp_status_prospect == 'P'">
            <b-button
              class="btn-icon"
              variant="success"
              v-b-tooltip.hover.noninteractive="'Change Status Pending'"
              v-if="moduleRole('change_status_pending')"
              @click="openModalPending(data.item)"
            >
              <i class="fas fa-file-alt"></i>
            </b-button>
            <b-button
              class="btn-icon"
              variant="outline-info"
              v-b-tooltip.hover.noninteractive="'View Detail'"
              :to="{ name: $route.name, params: { pageSlug: 'detail', pageId: data.item.tp_code } }"
            ><i class="fas fa-eye"></i>
            </b-button>
            <b-button
              class="btn-icon"
              variant="outline-warning"
              v-b-tooltip.hover.noninteractive="'Edit Prospect'"
              v-if="moduleRole('edit')"
              @click="editProspect(data.item)"
            ><i class="fas fa-pencil-alt"></i>
            </b-button>
          </template>
          <template v-else-if="data.item.tp_status_prospect == 'S'">
            <div class="d-flex align-items-center">
              <b-button
                class="btn-icon btn-icon-lg"
                variant="success"
                v-b-tooltip.hover.noninteractive="'Add Survey Result'"
                v-if="moduleRole('add_survey_result') && data.item.tss_id"
                @click="openModalSurveyResult(data.item)"
              >
                <i class="fas fa-file-alt"></i>
              </b-button>
              <b-button
                class="btn-icon btn-icon-lg"
                variant="success"
                v-b-tooltip.hover.noninteractive="'Add Survey'"
                v-else-if="moduleRole('add_survey_result') && !data.item.tss_id"
                @click="openModalSurvey(data.item)"
              >
                <i class="fas fa-file-alt"></i>
              </b-button>
              <div class="d-flex flex-column">
                <b-button
                    class="btn-icon"
                    variant="outline-info"
                    v-b-tooltip.hover.noninteractive="'View Detail'"
                    :to="{ name: $route.name, params: { pageSlug: 'detail', pageId: data.item.tp_code } }"
                  ><i class="fas fa-eye"></i>
                </b-button>
                <b-button
                  class="btn-icon"
                  variant="outline-warning"
                  v-b-tooltip.hover.noninteractive="'Edit Prospect'"
                  v-if="moduleRole('edit')"
                  @click="editProspect(data.item)"
                ><i class="fas fa-pencil-alt"></i>
                </b-button>

              </div>
            </div>
          </template>
          <template v-else-if="data.item.tp_status_prospect == 'R'">
            <b-button
                class="btn-icon"
                variant="outline-info"
                v-b-tooltip.hover.noninteractive="'View Detail'"
                :to="{ name: $route.name, params: { pageSlug: 'detail', pageId: data.item.tp_code } }"
              ><i class="fas fa-eye"></i>
            </b-button>
            <b-button
              class="btn-icon"
              variant="outline-warning"
              v-b-tooltip.hover.noninteractive="'Edit Prospect'"
              @click="editProspect(data.item)"
              v-if="moduleRole('edit')"
            ><i class="fas fa-pencil-alt"></i>
            </b-button>
          </template>
        </template>
      </b-table>
    </b-card-body>
    <b-card-footer>
      <template v-if="(dataList||[]).length&&data.total>10">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <label for="perPage" class="mb-0 mr-2">Show</label>
              <b-form-select
                size="sm"
                id="perPage"
                class="w-auto mx-50"
                v-model="perPage"
                :options="Config.dataShownOptions"
              />
            <label for="perPage" class="mb-0 ml-2">items per page</label>
          </div>
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </div>
      </template>
    </b-card-footer>
  </b-card>

  <template v-else>
    <Detail v-bind="dataDetail" />
  </template>

  <b-modal id="newProspect"
    :title="row.edit ? 'Edit Prospect' : 'Create Prospect'"
    centered
    no-close-on-backdrop
    size="lg"
  >
    <validation-observer v-slot="{ handleSubmit }" ref="VFormProspect">
      <b-form @submit.prevent="handleSubmit(addProspect())">
        <b-form-row v-if="row.edit">
          <b-col md=6>
            <b-form-group label="Prospect Date" label-for="proSurveySchedule">
              <span>{{ row.tp_date | moment("LLLL") }}</span>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-group v-if="!row.edit">
          <b-form-checkbox v-model="customerExists">Create From Existing Data</b-form-checkbox>
        </b-form-group>
        <b-form-row>
          <b-col md="6">
            <b-form-group label="Customer Name" label-for="proName" v-if="customerExists">
              <v-select id="proName" v-model="customerName" placeholder="Type Customer Name" :options="mrClient" label="mc_name" :reduce="v => v.mc_id" />
            </b-form-group>
            <b-form-group label="Customer Name" label-for="proName" v-else>
              <b-form-input id="proName" placeholder="e.g. Anna Puspita" v-model="row.tp_client_name" />
              <VValidate name="Customer Name" v-model="row.tp_client_name" :rules="mrValidation.tp_client_name" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Customer Type" label-for="proCusType">
              <v-select id="proCusType" v-model="row.tp_client_type" :options="customerTypeSelect" label="label" :reduce="v => v.value" placeholder="Customer Type" />
              <VValidate name="Customer Type" v-model="row.tp_client_type" :rules="mrValidation.tp_client_type" />
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="row.tp_client_type == 'company'">
            <b-form-group label="Company Name" label-for="custCompany">
              <b-form-input id="custCompany" v-model="row.tp_client_company" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Phone Number" label-for="proNumber">
              <b-input-group prepend="+62">
                <b-form-input id="proNumber" v-model="row.tp_client_phone" type="tel" placeholder="e.g. 8565xxxxxxx" :formatter="telp" />
              </b-input-group>
                <VValidate 
                  name="Phone Number" 
                  v-model="row.tp_client_phone" 
                  :rules="{regex:/^(\+\d{1,3}[- ]?)?\d{10,13}$/, required: true}" 
                />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="proCusType">
              <template #label>Email <small>(Optional)</small></template>
              <b-input-group>
                <template #prepend>
                  <b-input-group-text><i class="fas fa-at"></i></b-input-group-text>
                </template>
                <b-form-input id="proNumber" v-model="row.tp_client_email" type="email" placeholder="e.g. annapuspita@mail.com" />
              </b-input-group>
                <VValidate name="Email" :rules="mrValidation.tp_client_email" v-model="row.tp_client_email" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Address" label-for="proAddress">
              <b-form-textarea id="proAddress" v-model="row.tp_client_address" placeholder="e.g. Jl. Sudirman No. 5 Kuningan, Jakarta Barat - DKI Jakarta" rows="3" />
              <VValidate name="Address" :rules="mrValidation.tp_client_address" v-model="row.tp_client_address" />
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group label="Prospect Category" label-for="prospectCategory">
              <v-select id="prospectCategory" :options="prospectCategory" v-model="row.tp_prospect_category" label="label" :reduce="v => v.value" placeholder="Prospect Category" />
              <VValidate name="Prospect Category" :rules="mrValidation.tp_prospect_category" v-model="row.tp_prospect_category" />
            </b-form-group>
          </b-col>
          <b-col md="7">
            <b-form-group label="Purpose" label-for="proPurpose">
              <b-form-input id="proPurpose" v-model="row.tp_keperluan" placeholder="e.g. Pembuatan lemari custom untuk kamar berukuran 4x4M" rows="3" />
              <VValidate name="Purpose" :rules="mrValidation.tp_keperluan" v-model="row.tp_keperluan" />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col md="6">
            <b-form-group label="Source" label-for="proSource">
              <v-select id="proSource" :options="prospectSourceSelect" v-model="row.tp_source" label="label" :reduce="v => v.value" placeholder="Prospect Source" />
              <VValidate name="Source" :rules="mrValidation.tp_source" v-model="row.tp_source" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Contact Via" label-for="proVia">
              <v-select id="proVia" v-model="row.tp_contact_via" :options="contactMediaSelect" label="label" :reduce="v => v.value" placeholder="Contact Via" />
              <VValidate name="Contact Via" :rules="mrValidation.tp_contact_via" v-model="row.tp_contact_via" />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-group label="General Information" label-for="proGenInfo">
          <b-form-textarea id="proGenInfo" v-model="row.tp_general_information" />
        </b-form-group>
        <b-form-row v-if="!row.edit">
          <b-col md=6>
            <b-form-group label="Prospect Date" label-for="proSurveySchedule">
              <date-picker type="datetime" value-type="format" format="YYYY-MM-DD HH:mm:ss" id="proSurveySchedule"  prefix-class="crm" v-model="row.tp_date" :disabled-date="notAfterToday" />
              <VValidate name="Prospect Date" rules="required" v-model="row.tp_date" />
            </b-form-group>
          </b-col>
        </b-form-row>        

        <b-form-group v-if="!row.edit" label="Status" label-for="proStatus">
          <b-form-radio-group :options="statusProspectOptions" v-model="row.tp_status_prospect" />
          <VValidate name="Status" :rules="mrValidation.tp_status_prospect" v-model="row.tp_status_prospect" />
        </b-form-group>
        <b-form-row v-if="!row.edit">
          <template v-if="row.tp_status_prospect == 'FU'">
            <b-col md="6">
              <b-form-group label="Next Follow Up Schedule">
                <date-picker value-type="format" format="YYYY-MM-DD" id="proFollow" prefix-class="crm" v-model="row.tfu_date" :disabled-date="notBeforeToday" />
                <VValidate name="Next Follow Up" rules="required" v-model="row.tfu_date" />
              </b-form-group>
            </b-col>
          </template>
          <template v-else-if="row.tp_status_prospect == 'S'">
            <b-col md="4">
              <b-form-group label="Surveyor" label-for="folSurveyor">
                <v-select id="folSurveyor" v-model="row.tss_pic" :options="mrSurveyor" label="label" :reduce="v => v.value" placeholder="Select Surveyor" />
              </b-form-group>
                <VValidate name="Surveyor" rules="required" v-model="row.tss_pic" />
            </b-col>
            <b-col md="4">
              <b-form-group label="Survey Schedule" label-for="proSurveySchedule">
                <date-picker type="datetime" value-type="format" format="YYYY-MM-DD HH:mm:ss" id="proSurveySchedule"  prefix-class="crm" v-model="row.tss_date" :disabled-date="notBeforeToday"/>
                <VValidate name="Survey Schedule" rules="required" v-model="row.tss_date" />
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-form-group label="Survey Address" label-for="proSurveyAddress">
                <b-form-textarea id="proSurveyAddress" rows="3" v-model="row.tss_address" />
                <VValidate name="Survey Address" rules="required|min:5" v-model="row.tss_address" />
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-form-group label="Contact Person Name" label-for="proSurvCP">
                <b-form-input id="proSurvCP" v-model="row.tss_contact_person_name" />
                <VValidate name="Contact Person Name" rules="required|min:3" v-model="row.tss_contact_person_name" />
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-form-group label="Contact Person Phone" label-for="proSurvCPPhone">
                <b-form-input id="proSurvCPPhone" v-model="row.tss_contact_person_phone" type="number" :formatter="telp" placeholder="e.g Mobile Number"></b-form-input>
                <VValidate name="Mobile Number" v-model="row.tss_contact_person_phone" :rules="{regex:/^(\+\d{1,3}[- ]?)?\d{10,13}$/, required: true}" />
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-form-group label="Note" label-for="proSurveyNote">
                <b-form-textarea id="proSurveyNote" rows="3" v-model="row.tss_note" />
                <VValidate name="Note" rules="" v-model="row.tss_note" />
              </b-form-group>
            </b-col>
          </template>
          <template v-else-if="row.tp_status_prospect == 'C'">            
            <b-col md="10">
              <b-form-group label="Closing Date" label-for="proClosingDate">
                <date-picker type="date" value-type="format" format="YYYY-MM-DD" id="proClosingDate"  prefix-class="crm" v-model="row.tp_closing_date" :disabled-date="notAfterToday" />
                <VValidate name="Closing Date" rules="required" v-model="row.tp_closing_date" />
              </b-form-group>
            </b-col>

            <b-col md="10">
              <b-form-group label="Project Worth" label-for="proPrice">
                <b-input-group prepend="Rp.">
                  <money id="proPrice" v-model="row.tp_total_price" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                </b-input-group>
                <VValidate name="Project Worth" rules="required|min_value:100000" v-model="row.tp_total_price" />
              </b-form-group>
            </b-col>

            <b-col md="10">
              <div class="border p-3 mb-3">
                <h5 class="font-weight-bold">Revenue</h5>
                <b-form-row class="mb-2">
                  <b-col md="6">
                    <b-form-group label="Partner Revenue" label-for="proPartRevenue" class="mb-0">
                      <b-input-group prepend="Rp.">
                        <money id="proPartRevenue" @input="setRevenue(row)" v-model="row.tp_revenue_partner" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                      </b-input-group>                      
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Company Revenue" label-for="proCompRevenue" class="mb-0">
                      <b-input-group prepend="Rp.">
                        <money id="proCompRevenue" disabled v-model="row.tp_revenue_bu" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                      </b-input-group>                      
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col md="6">
                      <b-form-group label="Percent Partner" label-for="percentPartner">
                          <b-input-group append="%">
                          <b-form-input disabled type="number" min="0" id="percentPartner" v-model="percent_partner" v-bind="maskCurrency" placeholder="cth: 70" class="form-control"></b-form-input>
                          </b-input-group>
                          <VValidate name="Percent Partner" rules="required|max_value:100|min_value:1" v-model="percent_partner" />
                      </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Percent Company" label-for="percentBu">
                      <b-input-group append="%">
                        <b-form-input disabled type="number" min="0" id="percentBu" v-model="percent_bu" v-bind="maskCurrency" placeholder="cth: 30" class="form-control"></b-form-input>
                      </b-input-group>
                      <VValidate name="Percent Company" rules="required|max_value:100|min_value:1" v-model="percent_bu" />
                    </b-form-group>
                  </b-col>      
                </b-form-row>                
              </div>
            </b-col>

            <b-col md="12">
              <hr>
            </b-col>

            <b-col md="12">
              <b-form-group label="Using DP ?">
                <b-form-radio-group id="usingDP" :options="optionsDP" v-model="byUsingDP" />              
              </b-form-group>
            </b-col>
            <!-- Section DP -->
            <template v-if="byUsingDP">
              <b-col md="5">
                <b-form-group label="Down Payment (DP)" label-for="proDP">
                  <b-input-group prepend="Rp.">
                    <money id="proDP" v-model="row.tp_dp" @input="setPriceCompany(row)" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                  </b-input-group>
                  <VValidate name="Down Payment (DP)" :rules="{ required: true, min_value: 0, max_value: row.tp_total_price }" v-model="row.tp_dp" />
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group label="Date Down Payment (DP)" label-for="tpDate">
                  <date-picker v-model="row.tp_dp_date" value-type="format" format="YYYY-MM-DD" id="tpDate" prefix-class="crm"  />
                </b-form-group>
                <VValidate name="Date Down Payment (DP)" rules="required" v-model="row.tp_dp_date" />
              </b-col>
              <b-col md="10">
                <div class="border p-3 mb-3">
                  <h5 class="font-weight-bold">Down Payment Shares</h5>
                  <b-form-row>
                    <b-col md="6">
                      <b-form-group label="Partner Share" label-for="proPartShare" class="mb-0">
                        <b-input-group prepend="Rp.">
                          <money id="proPartShare" @input="setPriceCompany(row)" v-model="row.tp_price_partner" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                        </b-input-group>
                        <VValidate name="Company Share" :rules="{ required: true, min_value: 0, max_value: row.tp_dp }" v-model="row.tp_price_partner" />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Company Share" label-for="proCompShare" class="mb-0">
                        <b-input-group prepend="Rp.">
                          <money id="proCompShare" disabled v-model="row.tp_price_bu" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                        </b-input-group>
                        <VValidate name="Company Share" :rules="{ required: true, min_value: 0, max_value: row.tp_dp }" v-model="row.tp_price_bu" />
                      </b-form-group>
                    </b-col>
                  </b-form-row>                
                </div>
              </b-col>
              <b-col md="12">
                <b-form-group label="Description" label-for="proDP">
                  <b-form-input id="description" v-model="row.tcp_description" placeholder="e.g DP 50%" />
                  <VValidate name="Description" :rules="{ required: true, min: 2, max: 128 }" v-model="row.tcp_description" />
                </b-form-group>
              </b-col>
            </template>
            <!--  -->

            <template v-else>
              <b-col md="5">
                <b-form-group label="Transfer to Partner?" label-for="bailout">
                  <b-input-group prepend="Rp.">
                    <money id="bailout" v-model="row.tp_bailout" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                  </b-input-group>
                  <VValidate name="Transfer to Partner?" :rules="{max_value: row.tp_total_price}" v-model="row.tp_bailout" />
                </b-form-group>
              </b-col>
            </template>

            <b-col md="5">
              <b-form-group label="Partner Assignment" label-for="proPartner">
                <v-select id="proPartner" :options="mrPartner" :reduce="v => v.mp_id" v-model="row.tp_partner" label="mp_name" placeholder="Choose a partner to be assigned" />
                <VValidate name="Partner Assignment" rules="required" v-model="row.tp_partner" />
              </b-form-group>
            </b-col>
            <div class="w-100"></div>
            <b-col md="5">
              <b-form-group label="Project Date" label-for="proProjectDate">
                <date-picker @input="changeRangeDate(row)" range ref="startDate" v-model="row.tp_range_date" value-type="format" format="YYYY-MM-DD" id="proProjectDate" prefix-class="crm" :disabled-date="notBeforeToday" />
              </b-form-group>
              <VValidate name="Project Date" rules="required" v-model="row.tp_range_date" />
            </b-col>
            <b-col md="7">
            </b-col>
            <b-col md="6">
              <b-form-group label="Status Project" label-for="customerStatus">
                <b-form-radio-group id="customerStatus" :options="closingStatus" v-model="row.tp_status_customer" />
                <VValidate name="Status Project" rules="required" v-model="row.tp_status_customer" />
              </b-form-group>
            </b-col>
          </template>
          <template v-if="row.tp_status_prospect == 'R' || row.tp_status_prospect == 'P'">
            <b-col md="10">
              <b-form-group label-for="proReason" v-if="row.tp_status_prospect == 'R'">
                <template #label>
                  Rejection Reason {{row.tp_status_prospect}} <small>(Optional)</small>
                </template>
                <b-form-textarea v-model="row.tp_reject_reason" rows="3" placeholder="e.g. Customer masih belum yakin untuk membuat furnitur custom" />
              </b-form-group>
              <b-form-group label-for="proReason" v-else-if="row.tp_status_prospect == 'P'">
                <template #label>
                  Pending Reason <small>(Optional)</small>
                </template>
                <b-form-textarea v-model="row.tp_pending_reason" rows="3" placeholder="e.g. Customer masih belum yakin untuk membuat furnitur custom" />
              </b-form-group>
            </b-col>
          </template>
        </b-form-row>
        <b-form-group v-if="row.edit && row.tp_status_prospect == 'R'" label="Status" label-for="proStatus">
          <b-form-radio-group :options="statusRejectOption" v-model="row.tp_next_status" />
          <VValidate name="Status" :rules="mrValidation.tp_status_prospect" v-model="row.tp_next_status" />
        </b-form-group>
        <b-form-row v-if="row.edit && row.tp_status_prospect == 'R'">
          <template v-if="row.tp_next_status == 'FU'">
            <b-col md="6">
              <b-form-group label="Next Follow Up Schedule">
                <date-picker value-type="format" format="YYYY-MM-DD" id="proFollow" prefix-class="crm" v-model="row.tfu_date" :disabled-date="notBeforeToday" />
                <VValidate name="Next Follow Up" rules="required" v-model="row.tfu_date" />
              </b-form-group>
            </b-col>
          </template>
        </b-form-row>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <b-button size="md" variant="transparent" @click="closeModal('newProspect')">Cancel</b-button>
      <b-button size="md" variant="info" @click="addProspect()">{{row.edit ? 'Edit Prospect' : 'Create Prospect'}}</b-button>
    </template>
  </b-modal>

  <b-modal id="followUpResult"
    title="Follow Up Result"
    centered
    no-close-on-backdrop
  >
    <validation-observer v-slot="{ handleSubmit }" ref="VFormFollowUp">
      <b-form @submit.prevent="handleSubmit(addFollowUp())">
        <b-form-group label="Follow Up Date">
          <date-picker value-type="format" format="YYYY-MM-DD" id="followDate" prefix-class="crm" v-model="dataModal.tfu_date" :disabled-date="notBeforeToday" />
          <VValidate name="Follow Up Date" v-model="dataModal.tfu_date" rules="required" />
        </b-form-group>
        <b-form-group label="Follow Up By">
          <b-form-input id="folPIC" v-model="dataModal.tfu_person" placeholder="e.g. Mega Murdiana"></b-form-input>
          <VValidate name="Follow Up By" v-model="dataModal.tfu_person" rules="required|min:2" />
        </b-form-group>
        <b-form-group label="Follow Up Result">
          <b-form-textarea id="folResult" v-model="dataModal.tfu_result" placeholder="e.g. customer setuju untuk melakukan pemasangan meja marmer" rows="3" />
          <VValidate name="Result" v-model="dataModal.tfu_result" rules="required|min:5" />
        </b-form-group>
        <b-form-group label="Status" label-for="proStatus">
          <b-form-radio-group :options="statusProspectOptions" v-model="dataModal.tfu_status" />
          <VValidate name="Status" v-model="dataModal.tfu_status" rules="required" />
        </b-form-group>
        <b-form-row>
          <template v-if="dataModal.tfu_status == 'FU'">
            <b-col md="12">
              <b-form-group label="Next Follow Up Schedule">
                <date-picker value-type="format" format="YYYY-MM-DD" id="followDate" prefix-class="crm" v-model="dataModal.next" :disabled-date="notBeforeTodayFollowUp" />
                <VValidate name="Date" v-model="dataModal.next" rules="required" />
              </b-form-group>
            </b-col>
          </template>
           <template v-else-if="dataModal.tfu_status == 'S'">
            <b-col md="6">
              <b-form-group label="Surveyor" label-for="proSurveyor">
                <v-select id="proSurveyor" v-model="dataModal.tss_pic" :options="mrSurveyor" label="label" :reduce="v => v.value" placeholder="Select Surveyor" />
              </b-form-group>
              <VValidate name="Surveyor" rules="required" v-model="dataModal.tss_pic" />
            </b-col>
            <b-col md="6">
              <b-form-group label="Survey Schedule" label-for="proSurveySchedule">
                <date-picker type="datetime" value-type="format" format="YYYY-MM-DD HH:mm:ss" id="proSurveySchedule"  prefix-class="crm" v-model="dataModal.tss_date" :disabled-date="notBeforeToday"/>
                <VValidate name="Survey Schedule" rules="required" v-model="dataModal.tss_date" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Survey Address" label-for="proSurveyAddress">
                <b-form-textarea id="proSurveyAddress" rows="3" v-model="dataModal.tss_address" />
                <VValidate name="Survey Address" rules="required|min:5" v-model="dataModal.tss_address" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Contact Person Name" label-for="proSurvCP">
                <b-form-input id="proSurvCP" v-model="dataModal.tss_contact_person_name" />
                <VValidate name="Contact Person Name" rules="required|min:3" v-model="dataModal.tss_contact_person_name" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Contact Person Phone" label-for="proSurvCPPhone">
                <b-form-input id="proSurvCPPhone" v-model="dataModal.tss_contact_person_phone" type="number" :formatter="telp" placeholder="e.g Mobile Number"></b-form-input>
                <VValidate name="Mobile Number" v-model="dataModal.tss_contact_person_phone" :rules="{regex:/^(\+\d{1,3}[- ]?)?\d{10,13}$/, required: true}" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Note" label-for="proSurveyNote">
                <b-form-textarea id="proSurveyNote" rows="3" v-model="dataModal.tss_note" />
                <VValidate name="Note" rules="" v-model="dataModal.tss_note" />
              </b-form-group>
            </b-col>
          </template>
          <template v-else-if="dataModal.tfu_status == 'C'">            
            <b-col md="12">
              <b-form-group label="Closing Date" label-for="proClosingDate">
                <date-picker type="date" value-type="format" format="YYYY-MM-DD" id="proClosingDate"  prefix-class="crm" v-model="dataModal.tp_closing_date" :disabled-date="notAfterToday" />
                <VValidate name="Closing Date" rules="required" v-model="dataModal.tp_closing_date" />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Project Worth" label-for="proPrice">
                <b-input-group prepend="Rp.">
                  <money id="proPrice" v-model="dataModal.tp_total_price" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                </b-input-group>
                <VValidate name="Project Worth" rules="required|min_value:100000" v-model="dataModal.tp_total_price" />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <div class="border p-3 mb-3">
                <h5 class="font-weight-bold">Revenue</h5>
                <b-form-row class="mb-2">
                  <b-col md="6">
                    <b-form-group label="Partner Revenue" label-for="proPartRevenue" class="mb-0">
                      <b-input-group prepend="Rp.">
                        <money id="proPartRevenue" @input="setRevenue(dataModal)" v-model="dataModal.tp_revenue_partner" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                      </b-input-group>                      
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Company Revenue" label-for="proCompRevenue" class="mb-0">
                      <b-input-group prepend="Rp.">
                        <money id="proCompRevenue" disabled v-model="dataModal.tp_revenue_bu" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                      </b-input-group>                      
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col md="6">
                      <b-form-group label="Percent Partner" label-for="percentPartner">
                          <b-input-group append="%">
                          <b-form-input disabled type="number" min="0" id="percentPartner" v-model="percent_partner" v-bind="maskCurrency" placeholder="cth: 70" class="form-control"></b-form-input>
                          </b-input-group>
                          <VValidate name="Percent Partner" rules="required|max_value:100|min_value:1" v-model="percent_partner" />
                      </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Percent Company" label-for="percentBu">
                      <b-input-group append="%">
                        <b-form-input disabled type="number" min="0" id="percentBu" v-model="percent_bu" v-bind="maskCurrency" placeholder="cth: 30" class="form-control"></b-form-input>
                      </b-input-group>
                      <VValidate name="Percent Company" rules="required|max_value:100|min_value:1" v-model="percent_bu" />
                    </b-form-group>
                  </b-col>      
                </b-form-row>                
              </div>
            </b-col>

            <b-col md="12">
              <hr>
            </b-col>

            <b-col md="12">
              <b-form-group label="Using DP ?">
                <b-form-radio-group id="usingDP" :options="optionsDP" v-model="byUsingDP" />              
              </b-form-group>
            </b-col>
            <!-- Section DP -->
            <template v-if="byUsingDP">
              <b-col md="6">
                <b-form-group label="Down Payment (DP)" label-for="proDP">
                  <b-input-group prepend="Rp.">
                    <money id="proDP" v-model="dataModal.tp_dp" @input="setPriceCompany(dataModal)" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                  </b-input-group>
                  <VValidate name="Down Payment (DP)" :rules="{ required: true, min_value: 0, max_value: dataModal.tp_total_price }" v-model="dataModal.tp_dp" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Date Down Payment (DP)" label-for="tpDate">
                  <date-picker v-model="dataModal.tp_dp_date" value-type="format" format="YYYY-MM-DD" id="tpDate" prefix-class="crm"  />
                </b-form-group>
                <VValidate name="Date Down Payment (DP)" rules="required" v-model="dataModal.tp_dp_date" />
              </b-col>
              <b-col md="12">
                <div class="border p-3 mb-3">
                  <h5 class="font-weight-bold">Down Payment Shares</h5>
                  <b-form-row>
                    <b-col md="6">
                      <b-form-group label="Partner Share" label-for="proPartShare" class="mb-0">
                        <b-input-group prepend="Rp.">
                          <money id="proPartShare" @input="setPriceCompany(dataModal)" v-model="dataModal.tp_price_partner" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                        </b-input-group>
                        <VValidate name="Company Share" :rules="{ required: true, min_value: 0, max_value: dataModal.tp_dp }" v-model="dataModal.tp_price_partner" />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Company Share" label-for="proCompShare" class="mb-0">
                        <b-input-group prepend="Rp.">
                          <money id="proCompShare" disabled v-model="dataModal.tp_price_bu" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                        </b-input-group>
                        <VValidate name="Company Share" :rules="{ required: true, min_value: 0, max_value: dataModal.tp_dp }" v-model="dataModal.tp_price_bu" />
                      </b-form-group>
                    </b-col>
                  </b-form-row>                
                </div>
              </b-col>
              <b-col md="12">
                <b-form-group label="Description" label-for="proDP">
                  <b-form-input id="description" v-model="dataModal.tcp_description" placeholder="e.g DP 50%" />
                  <VValidate name="Description" :rules="{ required: true, min: 2, max: 128 }" v-model="dataModal.tcp_description" />
                </b-form-group>
              </b-col>
            </template>
            <!--  -->

            <template v-else>
              <b-col md="12">
                <b-form-group label="Transfer to Partner?" label-for="bailout">
                  <b-input-group prepend="Rp.">
                    <money id="bailout" v-model="dataModal.tp_bailout" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                  </b-input-group>
                  <VValidate name="Transfer to Partner?" :rules="{max_value: dataModal.tp_total_price}" v-model="dataModal.tp_bailout" />
                </b-form-group>
              </b-col>
            </template>
            <b-col md="12">
              <b-form-group label="Partner Assignment" label-for="proPartner">
                <v-select id="proPartner" :options="mrPartner" :reduce="v => v.mp_id" v-model="dataModal.tp_partner" label="mp_name" placeholder="Choose a partner to be assigned" />
                <VValidate name="Partner Assignment" rules="required" v-model="dataModal.tp_partner" />
              </b-form-group>
            </b-col>
            <div class="w-100"></div>
            <b-col md="12">
              <b-form-group label="Project Date" label-for="proProjectDate">
                <date-picker @input="changeRangeDate(dataModal)" range v-model="dataModal.tp_range_date" value-type="format" format="YYYY-MM-DD" id="proProjectDate" prefix-class="crm" :disabled-date="notBeforeToday" />
              </b-form-group>
              <VValidate name="Project Date" rules="required" v-model="dataModal.tp_range_date" />
            </b-col>
            <b-col md="6">
              <b-form-group label="Status Project" label-for="customerStatus">
                <b-form-radio-group id="customerStatus" :options="closingStatus" v-model="dataModal.tp_status_customer" />
                <VValidate name="Status Project" :rules="mrValidation.tp_status_customer" v-model="dataModal.tp_status_customer" />
              </b-form-group>
            </b-col>
          </template>
          <template v-if="dataModal.tfu_status == 'R' || dataModal.tfu_status == 'P'">
            <b-col md="12">
              <b-form-group label-for="proReason" v-if="dataModal.tfu_status == 'R'">
                <template #label>
                  Rejection Reason <small>(Optional)</small>
                </template>
                <b-form-textarea v-model="dataModal.tp_reject_reason" rows="3" placeholder="e.g. Customer masih belum yakin untuk membuat furnitur custom" />
              </b-form-group>
              <b-form-group label-for="proReason" v-else-if="dataModal.tfu_status == 'P'">
                <template #label>
                  Pending Reason <small>(Optional)</small>
                </template>
                <b-form-textarea v-model="dataModal.tp_pending_reason" rows="3" placeholder="e.g. Customer masih belum yakin untuk membuat furnitur custom" />
              </b-form-group>
            </b-col>
          </template>
        </b-form-row>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <b-button size="md" variant="transparent" @click="closeModal('followUpResult')">Cancel</b-button>
      <b-button size="md" variant="info" @click="addFollowUp()">Submit Result</b-button>
    </template>
  </b-modal>

  <!-- Survey result -->
  <b-modal
    id="surveyResult"
    title="Add Survey Result"
    centered
    no-close-on-backdrop
  >
    <validation-observer v-slot="{ handleSubmit }" ref="VFormSurveyResult">
      <b-form @submit.prevent="handleSubmit(addSurveyResult())">
        <b-form-row>
          <b-col md="6">
            <b-form-group label="Survey Date" label-for="monDate" label-class="mb-1">
              <span>{{ dataModal.tss_date | moment('LLL') }}</span>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="PIC" label-for="monPic" label-class="mb-1">
              <span>{{dataModal.tss_pic_name || ''}}</span>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col md="12">
            <b-form-group>
              <b-form-checkbox v-model="dataModal.reschedule">Reschedule Survey ?</b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="dataModal.reschedule">
            <b-form-group label="Reschedule Date" label-for="rescheduleDate">
              <date-picker type="datetime" value-type="format" format="YYYY-MM-DD HH:mm:ss" id="rescheduleDate" prefix-class="crm" v-model="dataModal.tss_reschedule_date" :disabled-date="notBeforeToday" />
            </b-form-group>
            <VValidate name="Reschedule Date" rules="required" v-model="dataModal.tss_reschedule_date" />
          </b-col>
        </b-form-row>
        <template v-if="!dataModal.reschedule">
          <b-form-group label="Survey Result" label-for="monResult">
            <b-form-textarea id="monResult" rows="3" placeholder="e.g. pemasangan sudah berjalan 50%" v-model="dataModal.tss_result" />
            <VValidate name="Survey Result" rules="required|min:5" v-model="dataModal.tss_result" />
          </b-form-group>
          <b-form-group label-for="monPhoto">
            <template #label>Photo <small>(Optional)</small></template>
            <Uploader v-model="dataModal.tss_image" :usePreview="true" type="survey_result" isMultiple/>
          </b-form-group>
          <b-form-checkbox v-model="dataModal.tss_next">Schedule Next Survey</b-form-checkbox>
          <div v-if="dataModal.tss_next">
            <hr>
            <b-form-row>
              <b-col md=12>
                <b-form-group label="Survey Schedule" label-for="surveyDate">
                  <date-picker type="datetime" value-type="format" format="YYYY-MM-DD HH:mm:ss" id="surveyDate" prefix-class="crm" v-model="dataModal.tss_next_date" :disabled-date="notBeforeTodaySurvey" />
                </b-form-group>
                  <VValidate name="Survey Schedule" rules="required" v-model="dataModal.tss_next_date" />
              </b-col>

              <b-col md=12>
                <b-form-group label="Surveyor" label-for="folSurveyor">
                  <v-select id="folSurveyor" v-model="dataModal.tss_pic" :options="mrSurveyor" label="label" :reduce="v => String(v.value)" placeholder="Select Surveyor" />
                </b-form-group>
                  <VValidate name="Surveyor" rules="required" v-model="dataModal.tss_pic" />
              </b-col>

              <b-col md="12">
                <b-form-group label="Note" label-for="proSurveyNote">
                  <b-form-textarea id="proSurveyNote" rows="3" v-model="dataModal.tss_note" />
                  <VValidate name="Note" rules="" v-model="dataModal.tss_note" />
                </b-form-group>
              </b-col>

            </b-form-row>
          </div>
          <div class="mt-2" v-else>
            <label for="">Next Status</label>
            <b-form-radio-group :options="statusSurveyResult" v-model="dataModal.next_status" />
            <VValidate name="Status" rules="required" v-model="dataModal.next_status" />
          </div>
          <b-form-row v-if="!dataModal.tss_next" class="mt-3">
            <template v-if="dataModal.next_status == 'FU'">
              <b-col md="12">
                <b-form-group label="Next Follow Up Schedule">
                  <date-picker value-type="format" format="YYYY-MM-DD" id="proFollow" prefix-class="crm" v-model="dataModal.tfu_date" :disabled-date="notBeforeToday" />
                  <VValidate name="Next Follow Up Schedule" rules="required" v-model="dataModal.tfu_date" />
                </b-form-group>
              </b-col>
            </template>
            <template v-else-if="dataModal.next_status == 'C'">              
              <b-col md="12">
                <b-form-group label="Closing Date" label-for="proClosingDate">
                  <date-picker type="date" value-type="format" format="YYYY-MM-DD" id="proClosingDate"  prefix-class="crm" v-model="dataModal.tp_closing_date" :disabled-date="notAfterToday" />
                  <VValidate name="Closing Date" rules="required" v-model="dataModal.tp_closing_date" />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Project Worth" label-for="proPrice">
                  <b-input-group prepend="Rp.">
                    <money id="proPrice" v-model="dataModal.tp_total_price" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                  </b-input-group>
                  <VValidate name="Project Worth" rules="required|min_value:100000" v-model="dataModal.tp_total_price" />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <div class="border p-3 mb-3">
                  <h5 class="font-weight-bold">Revenue</h5>
                  <b-form-row class="mb-2">
                    <b-col md="6">
                      <b-form-group label="Partner Revenue" label-for="proPartRevenue" class="mb-0">
                        <b-input-group prepend="Rp.">
                          <money id="proPartRevenue" @input="setRevenue(dataModal)" v-model="dataModal.tp_revenue_partner" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                        </b-input-group>                      
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Company Revenue" label-for="proCompRevenue" class="mb-0">
                        <b-input-group prepend="Rp.">
                          <money id="proCompRevenue" disabled v-model="dataModal.tp_revenue_bu" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                        </b-input-group>                      
                      </b-form-group>
                    </b-col>
                  </b-form-row>

                  <b-form-row>
                    <b-col md="6">
                        <b-form-group label="Percent Partner" label-for="percentPartner">
                            <b-input-group append="%">
                            <b-form-input disabled type="number" min="0" id="percentPartner" v-model="percent_partner" v-bind="maskCurrency" placeholder="cth: 70" class="form-control"></b-form-input>
                            </b-input-group>
                            <VValidate name="Percent Partner" rules="required|max_value:100|min_value:1" v-model="percent_partner" />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Percent Company" label-for="percentBu">
                        <b-input-group append="%">
                          <b-form-input disabled type="number" min="0" id="percentBu" v-model="percent_bu" v-bind="maskCurrency" placeholder="cth: 30" class="form-control"></b-form-input>
                        </b-input-group>
                        <VValidate name="Percent Company" rules="required|max_value:100|min_value:1" v-model="percent_bu" />
                      </b-form-group>
                    </b-col>      
                  </b-form-row>                  
                </div>
              </b-col>
              
              <b-col md="12">
                <hr>
              </b-col>

              <b-col md="12">
              <b-form-group label="Using DP ?">
                <b-form-radio-group id="usingDP" :options="optionsDP" v-model="byUsingDP" />              
              </b-form-group>
            </b-col>
            <!-- Section DP -->
            <template v-if="byUsingDP">
              <b-col md="6">
                <b-form-group label="Down Payment (DP)" label-for="proDP">
                  <b-input-group prepend="Rp.">
                    <money id="proDP" v-model="dataModal.tp_dp" @input="setPriceCompany(dataModal)" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                  </b-input-group>
                  <VValidate name="Down Payment (DP)" :rules="{ required: true, min_value: 0, max_value: dataModal.tp_total_price }" v-model="dataModal.tp_dp" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Date Down Payment (DP)" label-for="tpDate">
                  <date-picker v-model="dataModal.tp_dp_date" value-type="format" format="YYYY-MM-DD" id="tpDate" prefix-class="crm"  />
                </b-form-group>
                <VValidate name="Date Down Payment (DP)" rules="required" v-model="dataModal.tp_dp_date" />
              </b-col>
              <b-col md="12">
                <div class="border p-3 mb-3">
                  <h5 class="font-weight-bold">Down Payment Shares</h5>
                  <b-form-row>
                    <b-col md="6">
                      <b-form-group label="Partner Share" label-for="proPartShare" class="mb-0">
                        <b-input-group prepend="Rp.">
                          <money id="proPartShare" @input="setPriceCompany(dataModal)" v-model="dataModal.tp_price_partner" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                        </b-input-group>
                        <VValidate name="Company Share" :rules="{ required: true, min_value: 0, max_value: dataModal.tp_dp }" v-model="dataModal.tp_price_partner" />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Company Share" label-for="proCompShare" class="mb-0">
                        <b-input-group prepend="Rp.">
                          <money id="proCompShare" disabled v-model="dataModal.tp_price_bu" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                        </b-input-group>
                        <VValidate name="Company Share" :rules="{ required: true, min_value: 0, max_value: dataModal.tp_dp }" v-model="dataModal.tp_price_bu" />
                      </b-form-group>
                    </b-col>
                  </b-form-row>                
                </div>
              </b-col>
              <b-col md="12">
                <b-form-group label="Description" label-for="proDP">
                  <b-form-input id="description" v-model="dataModal.tcp_description" placeholder="e.g DP 50%" />
                  <VValidate name="Description" :rules="{ required: true, min: 2, max: 128 }" v-model="dataModal.tcp_description" />
                </b-form-group>
              </b-col>
            </template>
            <!--  -->

            <template v-else>
              <b-col md="12">
                <b-form-group label="Transfer to Partner?" label-for="bailout">
                  <b-input-group prepend="Rp.">
                    <money id="bailout" v-model="dataModal.tp_bailout" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                  </b-input-group>
                  <VValidate name="Transfer to Partner?" :rules="{max_value: dataModal.tp_total_price}" v-model="dataModal.tp_bailout" />
                </b-form-group>
              </b-col>
            </template>
              <b-col md="12">
                <b-form-group label="Partner Assignment" label-for="proPartner">
                  <v-select id="proPartner" :options="mrPartner" :reduce="v => v.mp_id" v-model="dataModal.tp_partner" label="mp_name" placeholder="Choose a partner to be assigned" />
                  <VValidate name="Partner Assignment" rules="required" v-model="dataModal.tp_partner" />
                </b-form-group>
              </b-col>
              <div class="w-100"></div>
              <b-col md="12">
                <b-form-group label="Project Date" label-for="proProjectDate">
                  <date-picker @input="changeRangeDate(dataModal)" range v-model="dataModal.tp_range_date" value-type="format" format="YYYY-MM-DD" id="proProjectDate" prefix-class="crm" :disabled-date="notBeforeToday" />
                </b-form-group>
                <VValidate name="Project Date" rules="required" v-model="dataModal.tp_range_date" />
              </b-col>
              <b-col md="6">
                <b-form-group label="Status Project" label-for="customerStatus">
                  <b-form-radio-group id="customerStatus" :options="closingStatus" v-model="dataModal.tp_status_customer" />
                  <VValidate name="Status Project" rules="required" v-model="dataModal.tp_status_customer" />
                </b-form-group>
              </b-col>
            </template>
            <template v-else-if="dataModal.next_status == 'R'">
              <b-col md="10">
                <b-form-group label-for="proReason">
                  <template #label>
                    Rejection Reason <small>(Optional)</small>
                  </template>
                  <b-form-textarea v-model="dataModal.tp_reject_reason" rows="3" placeholder="e.g. Customer masih belum yakin untuk membuat furnitur custom" />
                </b-form-group>
              </b-col>
            </template>
          </b-form-row>
        </template>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <b-button size="md" variant="transparent" @click="closeModal('surveyResult')">Cancel</b-button>
      <b-button size="md" variant="info" @click="addSurveyResult()">Submit Result</b-button>
    </template>
  </b-modal>

  <b-modal
    id="newSurvey"
    title="Add Survey"
    centered
    no-close-on-backdrop
  >
    <validation-observer v-slot="{ handleSubmit }" ref="VFormNewSurvey">
      <b-form @submit.prevent="handleSubmit(addNewSurvey())">
        <b-form-row>
            <b-col md="6">
              <b-form-group label="Surveyor" label-for="folSurveyor">
                <v-select id="folSurveyor" v-model="row.tss_pic" :options="mrSurveyor" label="label" :reduce="v => v.value" placeholder="Select Surveyor" />
              </b-form-group>
                <VValidate name="Surveyor" rules="required" v-model="row.tss_pic" />
            </b-col>
            <b-col md="6">
              <b-form-group label="Survey Schedule" label-for="proSurveySchedule">
                <date-picker type="datetime" value-type="format" format="YYYY-MM-DD HH:mm:ss" id="proSurveySchedule"  prefix-class="crm" v-model="row.tss_date" :disabled-date="notBeforeToday"/>
                <VValidate name="Survey Schedule" rules="required" v-model="row.tss_date" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Survey Address" label-for="proSurveyAddress">
                <b-form-textarea id="proSurveyAddress" rows="3" v-model="row.tss_address" />
                <VValidate name="Survey Address" rules="required|min:5" v-model="row.tss_address" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Contact Person Name" label-for="proSurvCP">
                <b-form-input id="proSurvCP" v-model="row.tss_contact_person_name" />
                <VValidate name="Contact Person Name" rules="required|min:3" v-model="row.tss_contact_person_name" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Contact Person Phone" label-for="proSurvCPPhone">
                <b-form-input id="proSurvCPPhone" v-model="row.tss_contact_person_phone" type="number" :formatter="telp" placeholder="e.g Mobile Number"></b-form-input>
                <VValidate name="Mobile Number" v-model="row.tss_contact_person_phone" :rules="{regex:/^(\+\d{1,3}[- ]?)?\d{10,13}$/, required: true}" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Note" label-for="proSurveyNote">
                <b-form-textarea id="proSurveyNote" rows="3" v-model="row.tss_note" />
                <VValidate name="Note" rules="" v-model="row.tss_note" />
              </b-form-group>
            </b-col>
        </b-form-row>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <b-button size="md" variant="transparent" @click="closeModal('newSurvey')">Cancel</b-button>
      <b-button size="md" variant="info" @click="addNewSurvey()">Add Survey</b-button>
    </template>
  </b-modal>

  <!-- Modal pending -->
  <b-modal
    id="modalPending"
    title="Change Status"
    centered
    no-close-on-backdrop
  >
    <validation-observer v-slot="{ handleSubmit }" ref="VFormPending">
      <b-form @submit.prevent="handleSubmit(addPendingStatus())">
        <b-form-row>
          <b-col md="6">
            <b-form-group label="Pending Reason" label-for="monDate" label-class="mb-1">
              <span>{{ dataModal.tp_pending_reason || '-' }}</span>
            </b-form-group>
          </b-col>
        </b-form-row>
        <div class="mt-2">
          <label for="">Next Status</label>
          <b-form-radio-group :options="statusPending" v-model="dataModal.next_status" />
          <VValidate name="Status" rules="required" v-model="dataModal.next_status" />
        </div>
        <b-form-row v-if="!dataModal.tss_next" class="mt-3">
          <template v-if="dataModal.next_status == 'FU'">
            <b-col md="12">
              <b-form-group label="Next Follow Up Schedule">
                <date-picker value-type="format" format="YYYY-MM-DD" id="proFollow" prefix-class="crm" v-model="dataModal.tfu_date" :disabled-date="notBeforeToday" />
                <VValidate name="Next Follow Up Schedule" rules="required" v-model="dataModal.tfu_date" />
              </b-form-group>
            </b-col>
          </template>
          <template v-else-if="dataModal.next_status == 'S'">
            <b-col md="6">
              <b-form-group label="Surveyor" label-for="folSurveyor">
                <v-select id="folSurveyor" v-model="dataModal.tss_pic" :options="mrSurveyor" label="label" :reduce="v => v.value" placeholder="Select Surveyor" />
                <VValidate name="Surveyor" rules="required" v-model="dataModal.tss_pic" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Survey Schedule" label-for="proSurveySchedule">
                <date-picker type="datetime" value-type="format" format="YYYY-MM-DD HH:mm:ss" id="proSurveySchedule"  prefix-class="crm" v-model="dataModal.tss_date" :disabled-date="notBeforeToday"/>
                <VValidate name="Survey Schedule" rules="required" v-model="dataModal.tss_date" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Survey Address" label-for="proSurveyAddress">
                <b-form-textarea id="proSurveyAddress" rows="3" v-model="dataModal.tss_address" />
                <VValidate name="Survey Address" rules="required|min:5" v-model="dataModal.tss_address" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Contact Person Name" label-for="proSurvCP">
                <b-form-input id="proSurvCP" v-model="dataModal.tss_contact_person_name" />
                <VValidate name="Contact Person Name" rules="required|min:3" v-model="dataModal.tss_contact_person_name" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Contact Person Phone" label-for="proSurvCPPhone">
                <b-form-input id="proSurvCPPhone" v-model="dataModal.tss_contact_person_phone" type="number" :formatter="telp" placeholder="e.g Mobile Number"></b-form-input>
                <VValidate name="Mobile Number" v-model="dataModal.tss_contact_person_phone" :rules="{regex:/^(\+\d{1,3}[- ]?)?\d{10,13}$/, required: true}" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Note" label-for="proSurveyNote">
                <b-form-textarea id="proSurveyNote" rows="3" v-model="dataModal.tss_note" />
                <VValidate name="Note" rules="" v-model="dataModal.tss_note" />
              </b-form-group>
            </b-col>
          </template>
          <template v-else-if="dataModal.next_status == 'C'">            
            <b-col md="12">
              <b-form-group label="Closing Date" label-for="proClosingDate">
                <date-picker type="date" value-type="format" format="YYYY-MM-DD" id="proClosingDate"  prefix-class="crm" v-model="dataModal.tp_closing_date" :disabled-date="notAfterToday" />
                <VValidate name="Closing Date" rules="required" v-model="dataModal.tp_closing_date" />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Project Worth" label-for="proPrice">
                <b-input-group prepend="Rp.">
                  <money id="proPrice" v-model="dataModal.tp_total_price" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                </b-input-group>
                <VValidate name="Project Worth" rules="required|min_value:100000" v-model="dataModal.tp_total_price" />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <div class="border p-3 mb-3">
                <h5 class="font-weight-bold">Revenue</h5>
                <b-form-row class="mb-2">
                  <b-col md="6">
                    <b-form-group label="Partner Revenue" label-for="proPartRevenue" class="mb-0">
                      <b-input-group prepend="Rp.">
                        <money id="proPartRevenue" @input="setRevenue(dataModal)" v-model="dataModal.tp_revenue_partner" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                      </b-input-group>                      
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Company Revenue" label-for="proCompRevenue" class="mb-0">
                      <b-input-group prepend="Rp.">
                        <money id="proCompRevenue" disabled v-model="dataModal.tp_revenue_bu" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                      </b-input-group>                      
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col md="6">
                      <b-form-group label="Percent Partner" label-for="percentPartner">
                          <b-input-group append="%">
                          <b-form-input disabled type="number" min="0" id="percentPartner" v-model="percent_partner" v-bind="maskCurrency" placeholder="cth: 70" class="form-control"></b-form-input>
                          </b-input-group>
                          <VValidate name="Percent Partner" rules="required|max_value:100|min_value:1" v-model="percent_partner" />
                      </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Percent Company" label-for="percentBu">
                      <b-input-group append="%">
                        <b-form-input disabled type="number" min="0" id="percentBu" v-model="percent_bu" v-bind="maskCurrency" placeholder="cth: 30" class="form-control"></b-form-input>
                      </b-input-group>
                      <VValidate name="Percent Company" rules="required|max_value:100|min_value:1" v-model="percent_bu" />
                    </b-form-group>
                  </b-col>      
                </b-form-row>                
              </div>
            </b-col>

            <b-col md="12">
              <hr>
            </b-col>

            <b-col md="12">
              <b-form-group label="Using DP ?">
                <b-form-radio-group id="usingDP" :options="optionsDP" v-model="byUsingDP" />              
              </b-form-group>
            </b-col>
            <!-- Section DP -->
            <template v-if="byUsingDP">
              <b-col md="6">
                <b-form-group label="Down Payment (DP)" label-for="proDP">
                  <b-input-group prepend="Rp.">
                    <money id="proDP" v-model="dataModal.tp_dp" @input="setPriceCompany(dataModal)" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                  </b-input-group>
                  <VValidate name="Down Payment (DP)" :rules="{ required: true, min_value: 0, max_value: dataModal.tp_total_price }" v-model="dataModal.tp_dp" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Date Down Payment (DP)" label-for="tpDate">
                  <date-picker v-model="dataModal.tp_dp_date" value-type="format" format="YYYY-MM-DD" id="tpDate" prefix-class="crm"  />
                </b-form-group>
                <VValidate name="Date Down Payment (DP)" rules="required" v-model="dataModal.tp_dp_date" />
              </b-col>
              <b-col md="12">
                <div class="border p-3 mb-3">
                  <h5 class="font-weight-bold">Down Payment Shares</h5>
                  <b-form-row>
                    <b-col md="6">
                      <b-form-group label="Partner Share" label-for="proPartShare" class="mb-0">
                        <b-input-group prepend="Rp.">
                          <money id="proPartShare" @input="setPriceCompany(dataModal)" v-model="dataModal.tp_price_partner" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                        </b-input-group>
                        <VValidate name="Company Share" :rules="{ required: true, min_value: 0, max_value: dataModal.tp_dp }" v-model="dataModal.tp_price_partner" />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Company Share" label-for="proCompShare" class="mb-0">
                        <b-input-group prepend="Rp.">
                          <money id="proCompShare" disabled v-model="dataModal.tp_price_bu" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                        </b-input-group>
                        <VValidate name="Company Share" :rules="{ required: true, min_value: 0, max_value: dataModal.tp_dp }" v-model="dataModal.tp_price_bu" />
                      </b-form-group>
                    </b-col>
                  </b-form-row>                
                </div>
              </b-col>
              <b-col md="12">
                <b-form-group label="Description" label-for="proDP">
                  <b-form-input id="description" v-model="dataModal.tcp_description" placeholder="e.g DP 50%" />
                  <VValidate name="Description" :rules="{ required: true, min: 2, max: 128 }" v-model="dataModal.tcp_description" />
                </b-form-group>
              </b-col>
            </template>
            <!--  -->

            <template v-else>
              <b-col md="12">
                <b-form-group label="Transfer to Partner?" label-for="bailout">
                  <b-input-group prepend="Rp.">
                    <money id="bailout" v-model="dataModal.tp_bailout" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                  </b-input-group>
                  <VValidate name="Transfer to Partner?" :rules="{max_value: dataModal.tp_total_price}" v-model="dataModal.tp_bailout" />
                </b-form-group>
              </b-col>
            </template>
            <b-col md="12">
              <b-form-group label="Partner Assignment" label-for="proPartner">
                <v-select id="proPartner" :options="mrPartner" :reduce="v => v.mp_id" v-model="dataModal.tp_partner" label="mp_name" placeholder="Choose a partner to be assigned" />
                <VValidate name="Partner Assignment" rules="required" v-model="dataModal.tp_partner" />
              </b-form-group>
            </b-col>
            <div class="w-100"></div>
            <b-col md="12">
              <b-form-group label="Project Date" label-for="proProjectDate">
                <date-picker @input="changeRangeDate(dataModal)" range v-model="dataModal.tp_range_date" value-type="format" format="YYYY-MM-DD" id="proProjectDate" prefix-class="crm" :disabled-date="notBeforeToday" />
              </b-form-group>
              <VValidate name="Project Date" rules="required" v-model="dataModal.tp_range_date" />
            </b-col>
            <b-col md="6">
              <b-form-group label="Status Project" label-for="customerStatus">
                <b-form-radio-group id="customerStatus" :options="closingStatus" v-model="dataModal.tp_status_customer" />
                <VValidate name="Status Project" rules="required" v-model="dataModal.tp_status_customer" />
              </b-form-group>
            </b-col>
          </template>
          <template v-else-if="dataModal.next_status == 'R'">
            <b-col md="10">
              <b-form-group label-for="proReason">
                <template #label>
                  Rejection Reason <small>(Optional)</small>
                </template>
                <b-form-textarea v-model="dataModal.tp_reject_reason" rows="3" placeholder="e.g. Customer masih belum yakin untuk membuat furnitur custom" />
              </b-form-group>
            </b-col>
          </template>
        </b-form-row>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <b-button size="md" variant="transparent" @click="closeModal('modalPending')">Cancel</b-button>
      <b-button size="md" variant="info" @click="addPendingStatus()">Submit Status</b-button>
    </template>
  </b-modal>
</b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen'
import Detail from './Detail.vue'
import moment from 'moment'

const d = new Date()

export default {
  extends: GlobalVue,
  components:{Detail},
  data() {
    return {
      dateNow: d.toISOString().slice(0, 10),
      dateFilter: [],
      idKey:'tp_id',
      mrValidation: {},
      sortingBy: [
        { value: 'fu', label: 'Follow Up' },
        { value: 'pc', label: 'Prospect Code' },
      ],
      statusSurveyResult: [
        { text: 'Follow Up', value: 'FU' },
        { text: 'Closing', value: 'C' },
        { text: 'Reject', value: 'R' },
      ],
      statusPending: [
        { text: 'Follow Up', value: 'FU' },
        { text: 'Survey', value: 'S' },
        { text: 'Closing', value: 'C' },
        { text: 'Reject', value: 'R' },
      ],
      closingStatus: [
        { text: 'Schedule', value: 'S' },
        { text: 'Running', value: 'R' },
      ],
      statusRejectOption: [
        { text: 'Follow Up', value: 'FU' },
        { text: 'Reject', value: 'R' },
      ],
      optionsDP:[
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      fields: [
        {
          key: 'number', label: '#', 
        },
        {
          key: 'tp_date', label: 'Prospect Date', 
        },
        {
          key: 'tp_client_name', label: 'Client Name',
        },
        {
          key: 'tp_client_address', label: 'Address', 
        },        
        {
          key: 'tp_keperluan', label: 'Category & Purpose', 
        },
        {
          key: 'tp_source', label: 'Source', 
        },
        {
          key: 'tp_contact_via', label: 'Contact Via', 
        },
        {
          key: 'tp_status_prospect', label: 'Status', 
          },
        {
          key: 'action', 
        },
      ],
      customerExists: false,
      byUsingDP: true,
      inputter: [],
      mrClient: [],
      mrPartner: [],
      mrSurveyor: [],
      prospectCategory: [],
      customerName: '',
      dataModal: {},
      percent_bu: 0,
      percent_partner: 0,
      total_fu: 0,
      total_survey: 0,
      total_pending: 0,
      total_reject: 0,
      total_all: 0,
      active_filter: '',
      dataDetail: {
        customerInformation: {
          tp_client_name: '',
          tp_contact_via: '',
          tp_source: '',
          tp_status_prospect: 'FU'
        }
      }
    }
  },
  mounted(){
    this.apiGet()
    this.active_filter = this.$route.query.status || ''
    this.$set(this.row, 'tp_status_prospect', 'FU')

    const a = []
    a[0] = this.$route.query['start-date'] || null
    a[1] = this.$route.query['end-date'] || null

    this.$set(this, 'dateFilter', a)
  },
  methods: {
    notAfterToday(date){
      return date > new Date()
    },
    changeRangeDate(data){
      this.$set(data, 'tp_start_date', data.tp_range_date[0])
      this.$set(data, 'tp_end_date', data.tp_range_date[1])
    },
    doFilterDate(newVal){
      this.$set(this.filter, 'start-date', newVal[0] || '')
      this.$set(this.filter, 'end-date', newVal[1] || '')
      this.doFilter()
    },
    setActiveMenu(status){
      this.active_filter = status

      this.$set(this.filter, 'status', status)
      this.doFilter()
    },
    openModalFollowUp(data){
      this.dataModal = Object.assign({}, data)
      this.row = {}
      this.$set(this.dataModal, 'tfu_status', data.tp_status_prospect)
      this.$bvModal.show('followUpResult')
    },
    openModalSurveyResult(data){
      this.$set(this, 'dataModal', Object.assign({}, data))
      this.row = {}
      this.$bvModal.show('surveyResult')
    },
    openModalSurvey(data){
      this.$set(this, 'dataModal', Object.assign({}, data))
      this.row = {}
      this.$set(this.row, 'tp_code', data.tp_code)
      this.$bvModal.show('newSurvey')
    },
    openModalPending(data){
      this.$set(this, 'dataModal', Object.assign({}, data))
      this.row = {}
      this.$bvModal.show('modalPending')
    },
    createProspect(value){
      this.customerExists = value
      this.$set(this, 'row', {})
      this.$set(this.row, 'tp_status_prospect', 'FU')
      Gen.apiRest('/get/' + this.modulePage + '/getClient').then(res => {
        this.mrClient = res.data.mrClient
        this.$bvModal.show('newProspect')
      })
    },
    editProspect(data){
      this.customerExists = false
      this.$set(this, 'row', Object.assign({}, data))
      this.$set(this.row, 'edit', true)
      this.$bvModal.show('newProspect')
    },
    notBeforeToday(date) {
      return date ?  false : false
      // return date < new Date(new Date().setHours(0, 0, 0, 0))
    },
    notBeforeTodayFollowUp(date) {
      return date ?  false : false
      // return date < new Date(new Date(this.dataModal.tfu_date).setHours(0,0,0,0))
    },
    notBeforeTodaySurvey(date) {
      return date ?  false : false
      // return date < new Date(this.dataModal.tss_date)
    },
    addProspect(){
      this.$refs.VFormProspect.validate().then(success => {
        if(!success) return

        this.$swal({
          title: 'Create Prospect...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        Gen.apiRest(
          '/do/' + this.modulePage,
          { data: {type: this.row.edit ? 'edit-prospect' : 'add-prospect', ...this.row, tp_percent_partner: this.percent_partner,  tp_percent_bu: this.percent_bu, tp_is_existing_prospect: this.customerExists, using_dp: this.byUsingDP} },
          'POST'
        ).then(res => {
          if(res){
              this.$swal({
                title: res.data.message,
                text: '',
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.$bvModal.hide('newProspect')
              })
          }
        }).catch(err => {
          this.$swal({
            title: err.response?.data?.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        })
      })
    },
    addNewSurvey(){
      this.$refs.VFormNewSurvey.validate().then(success => {
        if(!success) return

        this.$swal({
          title: 'Create Survey...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        Gen.apiRest(
          '/do/' + this.modulePage,
          { data: {type: 'add-new-survey', ...this.row} },
          'POST'
        ).then(res => {
          if(res){
              this.$swal({
                title: res.data.message,
                text: '',
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.$bvModal.hide('newSurvey')
              })
          }
        }).catch(() => {
          this.apiGet()
          this.$bvModal.hide('newSurvey')
        })
      })
    },
    addFollowUp(){
      this.$refs.VFormFollowUp.validate().then(success => {
        if(!success) return

        // if(this.dataModal.tfu_status == 'FU'){
        //   const currentDate = new Date(this.dataModal.tfu_date)
        //   const nextDate = new Date(this.dataModal.next)
        //   if(currentDate.getTime() > nextDate.getTime()){
        //     this.$swal({
        //       title: 'Next Follow Up schedule'
        //     })
        //   }
        // }

        this.$swal({
          title: 'Submit Follow Up Result...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        Gen.apiRest(
          '/do/' + this.modulePage,
          { data: {type: 'add-followUp', ...this.dataModal, tp_percent_partner: this.percent_partner, tp_percent_bu: this.percent_bu, tp_is_existing_prospect: this.customerExists, using_dp: this.byUsingDP} },
          'POST'
        ).then(res => {
          if(res){
              this.$swal({
                title: res.data.message,
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.$bvModal.hide('followUpResult')
              })
          }
        }).catch(() => {
            this.apiGet()
            this.$bvModal.hide('followUpResult')
        })
      })
    },
    addSurveyResult(){
      this.$refs.VFormSurveyResult.validate().then(success => {
        if(!success) return

        this.$swal({
          title: this.dataModal.reschedule ? 'Reschedule Survey...' : 'Add Survey Result...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        Gen.apiRest(
          '/do/' + this.modulePage,
          { data: {type: 'add-surveyResult', ...this.dataModal, tp_percent_partner: this.percent_partner, tp_percent_bu: this.percent_bu, tp_is_existing_prospect: this.customerExists, using_dp: this.byUsingDP} },
          'POST'
        ).then(res => {
          if(res){
              this.$swal({
                title: res.data.message,
                text: '',
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.$bvModal.hide('surveyResult')
              }).catch(() => {
                this.apiGet()
                this.$bvModal.hide('surveyResult')
              })
          }
        })
      })
    },
    addPendingStatus(){
      this.$refs.VFormPending.validate().then(success => {
        if(!success) return

        this.$swal({
          title: 'Change Status...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        Gen.apiRest(
          '/do/' + this.modulePage,
          { data: {type: 'add-statusPending', ...this.dataModal, tp_percent_partner: this.percent_partner, tp_percent_bu: this.percent_bu, tp_is_existing_prospect: this.customerExists, using_dp: this.byUsingDP} },
          'POST'
        ).then(res => {
          if(res){
              this.$swal({
                title: res.data.message,
                text: '',
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.$bvModal.hide('modalPending')
              })
          }
        }).catch(() => {
                this.apiGet()
                this.$bvModal.hide('modalPending')
              })
      })
    },
    closeModal(id, resetRow = true){
      this.$bvModal.hide(id)
      if(resetRow){
        this.$set(this, 'row', {})
      }
    },
    setPricePartner(data){
        const hasil = data['tp_dp'] - data['tp_price_bu']
        data['tp_price_partner'] = hasil
    },

    setPriceCompany(data){
        const hasil = data['tp_dp'] - data['tp_price_partner']
        data['tp_price_bu'] = hasil
    },
    setRevenue(data){
      const hasilCompany = data['tp_total_price'] - data['tp_revenue_partner'] 

      data['tp_revenue_bu'] = hasilCompany
      
      this.$set(this, 'percent_bu', this.getPercentage(hasilCompany, data['tp_total_price']))
      this.$set(this, 'percent_partner', this.getPercentage(data['tp_revenue_partner'], data['tp_total_price']))
    },    
    setPercentRevenue(){
      this.$set(this, 'percent_bu', 100 - this.percent_partner)

      if(this.row.tp_total_price || this.dataModal.tp_total_price) {
        this.setRevenue(this.dataModal || this.row)
      }
    },
    percentage(percent, totalValue) {
      return ((100 - percent)/100) * totalValue
    },
    
    hasFollowup(item, type){
      if(!item && type != "row") return 

      if(item.tp_status_prospect == 'FU' && item.tfu_date){
        if(moment(item.tfu_date).format('YYYY-MM-DD') == this.dateNow) return 'table-success'
        else if(item.tfu_date < this.dateNow) return 'table-danger'
      }

      if(item.has_followup == "Y") return "table-primary"
    }
  },
  computed: {
  },
  watch:{
    $route(){
      this.apiGet()
    },
    'filter'(){
      if('inputter' in this.filter == false){
        this.$set(this.filter, 'inputter', '')
      }
      if('ct' in this.filter == false){
        this.$set(this.filter, 'ct', '')
      }
      if('ps' in this.filter == false){
        this.$set(this.filter, 'ps', '')
      }
      if('cm' in this.filter == false){
        this.$set(this.filter, 'cm', '')
      }

      const a = []
      a[0] = this.filter['start-date'] || null
      a[1] = this.filter['end-date'] || null
      this.$set(this, 'dateFilter', a)
    },
    customerExists(newVal){
      if(!newVal){
          this.$set(this.row, 'tp_client_name', '')
          this.$set(this.row, 'tp_client_type', '')
          this.$set(this.row, 'tp_client_phone', '')
          this.$set(this.row, 'tp_client_email', '')
          this.$set(this.row, 'tp_client_address', '')
      }
      this.$set(this.row, 'customer_exist', newVal)
    },
    customerName(newVal){
      this.$set(this.row, 'tp_client_name', this.mrClient.find(v => v.mc_id == newVal).mc_name)
      this.$set(this.row, 'tp_client_id', newVal)
      if(!this.customerExists) return

      const cust = this.mrClient.find(v => v.mc_id == this.customerName)
      if(cust){
        this.$set(this.row, 'tp_client_type', cust.mc_type)
        this.$set(this.row, 'tp_client_phone', cust.mc_phone)
        this.$set(this.row, 'tp_client_email', cust.mc_email)
        this.$set(this.row, 'tp_client_address', cust.mc_address)
        this.$set(this.row, 'tp_client_company', cust.mc_company)
      }
    },
    'row.tp_status_prospect': {
      handler(newVal){
        if(newVal == 'S'){
          this.$set(this.row, 'tss_address',this.row.tp_client_address)
          this.$set(this.row, 'tss_contact_person_name',this.row.tp_client_name)
          this.$set(this.row, 'tss_contact_person_phone',this.row.tp_client_phone)
        }
      },
      deep: true
    },
    'dataModal.tfu_status': {
      handler(newVal){
        if(newVal == 'S'){
          this.$set(this.dataModal, 'tss_address',this.dataModal.tp_client_address)
          this.$set(this.dataModal, 'tss_contact_person_name',this.dataModal.tp_client_name)
          this.$set(this.dataModal, 'tss_contact_person_phone',this.dataModal.tp_client_phone)
        }
      },
      deep: true
    }
  }
}
</script>